import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';
import { Link } from 'gatsby';

import { getAllStudentVMGQL } from '../../apollo/student';
import $ from '../../styles/global';

const Container = styled.div`
  width: 550px;
  margin: 0 auto;
  padding-top: ${$.layout().padding4}px;
`;

const Title = styled.div`
  font-size: 30px;
  color: ${$.color.black1};
  text-align: center;
  letter-spacing: -1px;
  text-transform: uppercase;
  font-family: Ruda Bold;
  margin-bottom: ${$.layout().margin4}px;
`;

const Table = styled.table`
  width: 100%;
  text-align: center;

  & a {
    font-family: Ruda Bold;
  }

  & td {
    padding: ${$.layout().padding2}px;
  }

  & thead {
    background-color: ${$.color.black1};
    color: ${$.color.white};
  }

  & tbody {
    tr {
      &:nth-child(odd) {
        background-color: ${$.color.gray1};
      }
    }
  }
`;

const StudentIPsList = () => {
  const [students, setStudents] = useState([]);
  const [getAllStudentVM] = useLazyQuery(getAllStudentVMGQL, {
    onCompleted: ({ students: results }) => {
      setStudents(results);
    },
    onError: e => {
      console.log(e);
    },
  });

  useEffect(() => {
    getAllStudentVM();
  }, []);

  return (
    <Container>
      <Title>Active Student VMs</Title>
      <Table>
        <thead>
          <tr>
            <td>Instructor Name</td>
            <td>Student Name</td>
            <td>Student IP Address</td>
            <td>Go to VM</td>
          </tr>
        </thead>
        <tbody>
          {students.length > 0 ? (
            students.map(({ studentId, name, ipAddress, instructor }) => {
              const withoutPort = ipAddress.replace(':5901', '');

              return (
                <tr key={studentId}>
                  <td>{instructor.name}</td>
                  <td>{name}</td>
                  <td>{withoutPort}</td>
                  <td>
                    <Link to={`/instructor/vm/?ip=${withoutPort}`}>Go</Link>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default StudentIPsList;
