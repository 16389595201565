import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SEO from '../../components/seo';
import Utils from '../../utils';
import StudentVMList from '../../components/instructor/StudentVMList';

const StudentIPsPage = () => {
  const state = useSelector(({ user }) => {
    return { user };
  });

  /**
   * Entry point. We kick out anyone who is not an instructor before we begin requesting
   * for a class session.
   */
  useEffect(() => {
    Utils.redirect(state.user.role);
  }, []);

  return (
    <>
      <SEO
        title="Active Student VMs | SG Code Campus Online Platform App"
        description="Learn to code in SG Code Campus's very own online learning platform."
      />
      <StudentVMList />
    </>
  );
};

export default StudentIPsPage;
